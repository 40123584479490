import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { GlobalConstants } from '../../common/global-constants';
import { BOLogin } from '../../models/BOLogin.model';
import { CryptoService } from '../../services/crypto.service';
import { LoginService } from '../../services/login.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html'
})
export class LoginComponent implements OnDestroy {
  loginForm: FormGroup;

  busy: Subscription;
  submitted = false;

  constructor(public toastr: ToastrService, private formBuilder: FormBuilder, private _loginService: LoginService,
    private _cryptoService: CryptoService, private router: Router, private location: Location) {
    localStorage.clear();
    this.loginForm = this.formBuilder.group({
      userName: ['', [Validators.required, Validators.maxLength(100)]],
      pwd: ['', [Validators.required, Validators.maxLength(20)]]
    });
  }

  loginUser() {
    this.submitted = true;
    const loginInput: BOLogin = {
      userName: this._cryptoService.encryptUsingAES256(this.loginForm.get('userName')!.value).toString(),
      pwd: this._cryptoService.encryptUsingAES256(this.loginForm.get('pwd')!.value).toString()
    };
    this.busy = this._loginService.loginUser(loginInput).pipe(finalize(() => { this.submitted = false }))
      .subscribe(data => {
        this.toastr.success('Login Sucessful', 'Login Sucessful');
        localStorage.setItem('User', data.userName);
        localStorage.setItem('Role', data.role.toString());
        localStorage.setItem('Id', data.id.toString());
        //Ric
        localStorage.setItem('token',data.token?.toString());
        //Ric end
        this.location.replaceState('/'); // clears browser history so they can't navigate with back button
        this.router.navigate(['dashboard']);
      },
      err=>{
        this.toastr.error('usuario o contraseña inválidos');
      });
  }

  get registerFormControl() {
    return this.loginForm.controls;
  }

  ngOnDestroy(): void {
    this.busy?.unsubscribe();
  }
}

